import { registerLocaleData } from '@angular/common';
import {
	HTTP_INTERCEPTORS,
	HttpClient,
	HttpClientModule,
} from '@angular/common/http';
import localeNLExtra from '@angular/common/locales/extra/nl';
import localeNL from '@angular/common/locales/nl';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import {
	ApmErrorHandler,
	ApmModule,
	ApmService,
} from '@elastic/apm-rum-angular';
import { NgxTranslateCutModule } from '@gunnaway/ngx-translate-cut';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { AuthInterceptor } from './interceptors/auth/auth.interceptor';
import { TraceContextInterceptor } from './interceptors/trace-context/trace-context.interceptor';
import { AppInitializeService } from './services/app-initialize/app-initialize.service';

export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(
		http,
		`./assets/i18n/`,
		`.json?t=${Date.now()}`,
	);
}

registerLocaleData(localeNL, 'nl-NL', localeNLExtra);

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		HttpClientModule,
		ApmModule,
		RouterModule.forRoot(appRoutes, {
			anchorScrolling: 'enabled',
			bindToComponentInputs: true,
			initialNavigation: 'enabledBlocking',
			scrollPositionRestoration: 'enabled',
		}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient],
			},
			defaultLanguage: 'nl',
		}),
		NgxTranslateCutModule,
	],
	providers: [
		ApmService,
		AppInitializeService,
		{
			provide: ErrorHandler,
			useClass: ApmErrorHandler,
		},
		{
			provide: 'apiBaseUrl',
			useValue: environment.apiBaseUrl,
		},
		{
			provide: 'apiSource',
			useValue: environment.source,
		},
		{
			multi: true,
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
		},
		{
			multi: true,
			provide: HTTP_INTERCEPTORS,
			useClass: TraceContextInterceptor,
		},
		{
			provide: LOCALE_ID,
			useValue: 'nl-NL',
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	/* istanbul ignore next */
	constructor(private readonly appInitializeService: AppInitializeService) {
		this.appInitializeService.init();
	}
}
