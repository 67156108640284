import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { authStoreName } from 'provider-authenticate/lib/authenticate.service';
import { Observable, catchError, throwError } from 'rxjs';
import { RouteNames } from '../../app.routes';
import { DEED_QUESTIONNAIRE_TOKEN } from '../../pages/intake/intake.component';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private router: Router) {}

	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler,
	): Observable<HttpEvent<unknown>> {
		return this.handleRequest(
			next,
			this.updateHeadersAndReturnRequest(request, this.getAuthToken()),
		);
	}

	private getAuthToken(): string | undefined {
		return (
			JSON.parse(sessionStorage.getItem(authStoreName) || '{}').token ||
			sessionStorage.getItem(DEED_QUESTIONNAIRE_TOKEN) ||
			undefined
		);
	}

	private handleRequest(
		next: HttpHandler,
		request: HttpRequest<unknown>,
	): Observable<never | HttpEvent<unknown>> {
		return next.handle(request).pipe(
			catchError((error) => {
				if (
					error instanceof HttpErrorResponse &&
					error.status === 401
				) {
					this.router.navigate(['/' + RouteNames.INTAKE]);
				}
				return throwError(() => error);
			}),
		);
	}

	private updateHeadersAndReturnRequest(
		request: HttpRequest<unknown>,
		token: string | undefined,
	): HttpRequest<unknown> {
		if (token) {
			request = request.clone({
				setHeaders: { Authorization: `Bearer ${token}` },
			});
		}
		return request;
	}
}
