<div
	class="flex h-full flex-col bg-mint-100 py-safe sm:grid sm:grid-cols-[200px_1fr] sm:grid-rows-[max-content_1fr] lg:grid-cols-[288px_1fr]"
>
	<div
		class="col-span-2 sm:bg-white sm:py-2"
		[ngClass]="{
			'w-full py-1 sm:col-span-1 ': hasMenu,
			'bg-white py-4': !hasMenu
		}"
		*ngIf="hasHeader"
	>
		<a
			href="https://www.verklaringvanerfrecht.nl/"
			[title]="'global.to-verklaringvanerfrecht-title' | translate"
			target="_blank"
		>
			<img
				class="mx-auto"
				[ngClass]="{
					'h-6 sm:h-12': hasMenu,
					'h-20': !hasMenu
				}"
				src="assets/images/logo.svg"
				[alt]="'global.logo-alt-text' | translate"
			/>
		</a>
	</div>
	<div
		#scrollContainer
		class="col-span-2 flex-1 sm:order-3 sm:row-span-2 sm:overflow-auto"
		[ngClass]="{
			'sm:col-span-1': hasMenu
		}"
	>
		<router-outlet></router-outlet>
	</div>
</div>
